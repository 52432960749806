/* Default (Base) ----------- */
.aboutme-container {
  margin: 200px 100px 0px 100px;
}

#aboutme-picture {
  margin: 55px 0 0 0;
  opacity: 0.5;
  position: relative;
  top: -50px;
  width: 500px;
}

h1#aboutme-h1 {
  color: rgba(255, 255, 255, 1);
  font-size: 60px;
  font-weight: 200;
  letter-spacing: 3px;
  margin: 0px 0px 0px 0px;
  -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadeIn 7s; /* Firefox < 16 */
    -ms-animation: fadeIn 7s; /* Internet Explorer */
     -o-animation: fadeIn 7s; /* Opera < 12.1 */
        animation: fadeIn 7s;
}

h2#aboutme-h2 {
  color: rgba(181, 224, 242, 1);
  font-size: 40px;
  font-weight: 200;
  letter-spacing: 1px;
  margin: 25px 0px 0px 0px;
  text-align: justify;
  -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadeIn 7s; /* Firefox < 16 */
    -ms-animation: fadeIn 7s; /* Internet Explorer */
     -o-animation: fadeIn 7s; /* Opera < 12.1 */
        animation: fadeIn 7s;
}

h3#aboutme-h3 {
  color: rgba(181, 224, 242, 1);
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 50px 0px 50px 0px;
  text-align: justify;
  -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadeIn 7s; /* Firefox < 16 */
    -ms-animation: fadeIn 7s; /* Internet Explorer */
     -o-animation: fadeIn 7s; /* Opera < 12.1 */
        animation: fadeIn 7s;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
  .aboutme-container {
    margin: 200px 10px 0px 10px;
  }

  #aboutme-picture {
    margin: 25px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -20px;
    width: 100%;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 42px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 10px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 15px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 0px 50px 0px;
    text-align: justify;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
  .aboutme-container {
    margin: 200px 40px 0px 40px;
  }

  #aboutme-picture {
    margin: 25px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -20px;
    width: 50%;
    display: inline;
    float: left;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 42px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 10px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 15px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 0px 50px 0px;
    text-align: justify;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
  .aboutme-container {
    margin: 200px 10px 0px 10px;
  }

  #aboutme-picture {
    margin: 25px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -20px;
    width: 100%;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 42px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 10px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 15px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 0px 50px 0px;
    text-align: justify;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
  .aboutme-container {
    margin: 200px 100px 0px 100px;
  }

  #aboutme-picture {
    margin: 55px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -50px;
    width: 100%;
    overflow: hidden;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 0px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 20px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 12px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 25px 0px 50px 0px;
    text-align: justify;
  }
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .aboutme-container {
    margin: 200px 100px 0px 100px;
  }

  #aboutme-picture {
    margin: 55px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -50px;
    width: 100%;
    overflow: hidden;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 0px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 12px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 25px 0px 50px 0px;
    text-align: justify;
  }
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  .aboutme-container {
    margin: 200px 100px 0px 100px;
  }

  #aboutme-picture {
    margin: 55px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -50px;
    width: 100%;
    overflow: hidden;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 0px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 12px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 25px 0px 50px 0px;
    text-align: justify;
  }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
  .aboutme-container {
    margin: 200px 100px 0px 100px;
  }

  #aboutme-picture {
    margin: 55px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -50px;
    width: 100%;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 60px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 0px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 25px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 50px 0px 50px 0px;
    text-align: justify;
  }
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  .aboutme-container {
    margin: 400px 100px 0px 100px;
  }

  #aboutme-picture {
    margin: 55px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -50px;
    width: 700px;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 60px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 0px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 25px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 50px 0px 50px 0px;
    text-align: justify;
  }
}

/* iPhone 5 & 5S --------------------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {
  .aboutme-container {
    margin: 200px 0px 0px 20px;
  }

  #aboutme-picture {
    margin: 25px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -20px;
    width: 100%;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 42px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 10px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 15px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 0px 50px 0px;
    text-align: justify;
  }
}

/* iPhone 5 & 5S landscape ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
  .aboutme-container {
    margin: 200px 0px 0px 20px;
  }

  #aboutme-picture {
    margin: 25px 0 0 0;
    opacity: 0.5;
    position: relative;
    top: -20px;
    width: 50%;
  }

  h1#aboutme-h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 42px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 0px 0px 10px 0px;
  }

  h2#aboutme-h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 24px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 15px 0px 0px 0px;
    text-align: justify;
  }

  h3#aboutme-h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 0px 50px 0px;
    text-align: justify;
  }
}
