#navbar {
  background: linear-gradient(to bottom,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
  background: -moz-linear-gradient(to bottom,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
  background: -webkit-linear-gradient(top,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
  /*background-color: rgba(88,78,82,1);*/
  width: 100%;
  position: fixed;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  top: 0px;
  right: 0px;
  z-index: 5;
}

#navbar-mobile {
  display: none; /*By default, navbar-mobile is disabled.*/
}

#navbar-logo {
  cursor: pointer;
  display: inline;
  float: right;
  height: 100px;
  margin: 30px 30px 10px 20px;
  position: relative;
  border-radius: 10em 10em 10em 10em;
  border: solid 5px rgba(181, 224, 242, 0.1);
}

  #navbar-logo:hover, #navbar-logo:focus, #navbar-logo:active {
    border: solid 5px rgba(181, 224, 242, 1);
  }

  #navbar-logo:hover:active {
    border: solid 5px rgba(168, 86, 118, 1);
  }

ul#navbar-list {
  background-color: rgba(181, 224, 242, 0);
  color: rgba(181, 224, 242, 1);
  font-weight: 500;
  font-size: 25px;
  float: right;
  letter-spacing: 3px;
  list-style-type: none;
  margin: 60px 20px 0px 20px;
  padding: 0;
  position: relative;
}

ul#navbar-list li {
  background-color: rgba(181, 224, 242, 0);
}

li#navbar-list-item-first {
  background-color: none;
  display: inline;
  margin-right: 25px;
}

#navbar-first-Link {
  background-color: rgba(181, 224, 242, 0);
  color: rgba(181, 224, 242, 1);
  cursor: pointer;
  text-decoration: none;
}

#navbar-first-Link:hover, #navbar-first-Link:active {
  color: rgba(255, 255, 255, 1);
}

li#navbar-list-item-middle {
  background-color: none;
  display: inline;
  margin-left: 25px;
  margin-right: 25px;
}

#navbar-middle-Link {
  background-color: none;
  color: rgba(181, 224, 242, 1);
  cursor: pointer;
  text-decoration: none;
}

  #navbar-middle-Link:hover, #navbar-first-Link:active {
    color: rgba(255, 255, 255, 1);
  }

li#navbar-list-item-last {
  background-color: none;
  display: inline;
  margin-left: 25px;
}

#navbar-last-Link {
  background-color: none;
  color: rgba(181, 224, 242, 1);
  cursor: pointer;
  text-decoration: none;
}

  #navbar-last-Link:hover, #navbar-first-Link:active {
    color: rgba(255, 255, 255, 1);
  }

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
  #navbar {
    display: none;
  }

  #navbar-mobile {
    background-color: rgba(181, 224, 242, 0);
    position: fixed;
    display: inline-block;
    right: 0px;
    top: 0px;
    z-index: 5;
    margin: 20px 20px 0px 0px;
  }

  #navbar-logo {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: inline;
    height: 70px;
    margin: 0px 10px 10px 10px;
    right: 0px;
    position: fixed;
    border-radius: 10em 10em 10em 10em;
    border: solid 5px rgba(181, 224, 242, 0.1);
  }

    #navbar-logo:hover, #navbar-logo:focus, #navbar-logo:active {
      border: solid 5px rgba(181, 224, 242, 1);
    }

  .nav-link {
    background-color: rgba(181, 224, 242, 0);
  }

  .dropdown-menu {
    -webkit-animation: fadeIn 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 0.5s; /* Firefox < 16 */
        -ms-animation: fadeIn 0.5s; /* Internet Explorer */
         -o-animation: fadeIn 0.5s; /* Opera < 12.1 */
            animation: fadeIn 0.5s;
    background-color: rgba(88, 78, 82, 1);
    border: solid 0px rgba(181, 224, 242, 0);
    border-radius: 1em 1em 1em 1em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    margin: 50px 0 0 0;
    width: 75vw;
  }

  .dropdown-item {
    color: rgba(181, 224, 242, 1);
    letter-spacing: 2px;
    height: 10vh;
    padding: 3vh 0px 0px 10px;
    text-align: center;
  }

  .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:focus:active  {
    background-color: rgba(178, 86, 114, 1);
    color: rgba(181, 224, 242, 1);
  }

}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
  #navbar {
    display: none;
  }

  #navbar-mobile {
    position: fixed;
    display: inline-block;
    right: 0px;
    top: 0px;
    z-index: 5;
    margin: 20px 20px 0px 0px;
  }

  #navbar-logo {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: inline;
    height: 70px;
    margin: 0px 10px 10px 10px;
    right: 0px;
    position: fixed;
    border-radius: 10em 10em 10em 10em;
    border: solid 5px rgba(181, 224, 242, 0.1);
  }

    #navbar-logo:hover, #navbar-logo:focus, #navbar-logo:active {
      border: solid 5px rgba(181, 224, 242, 1);
    }

  .nav-link {
    background-color: rgba(181, 224, 242, 0);
  }

  .dropdown-menu {
    -webkit-animation: fadeIn 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 0.5s; /* Firefox < 16 */
        -ms-animation: fadeIn 0.5s; /* Internet Explorer */
         -o-animation: fadeIn 0.5s; /* Opera < 12.1 */
            animation: fadeIn 0.5s;
    background-color: rgba(88, 78, 82, 1);
    border: solid 0px rgba(181, 224, 242, 0);
    border-radius: 1em 1em 1em 1em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    margin: 50px 0 0 0;
    width: 75vw;
  }

  .dropdown-item {
    color: rgba(181, 224, 242, 1);
    letter-spacing: 2px;
    height: 10vh;
    padding: 3vh 0px 0px 10px;
    text-align: center;
  }

  .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:focus:active  {
    background-color: rgba(178, 86, 114, 1);
    color: rgba(181, 224, 242, 1);
  }

}

/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
  #navbar {
    display: none;
  }

  #navbar-mobile {
    position: fixed;
    display: inline-block;
    right: 0px;
    top: 0px;
    z-index: 5;
    margin: 20px 20px 0px 0px;
  }

  #navbar-logo {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: inline;
    height: 70px;
    margin: 0px 10px 10px 10px;
    right: 0px;
    position: fixed;
    border-radius: 10em 10em 10em 10em;
    border: solid 5px rgba(181, 224, 242, 0.1);
  }

    #navbar-logo:hover, #navbar-logo:focus, #navbar-logo:active {
      border: solid 5px rgba(181, 224, 242, 1);
    }

  .nav-link {
    background-color: rgba(181, 224, 242, 0);
  }

  .dropdown-menu {
    -webkit-animation: fadeIn 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 0.5s; /* Firefox < 16 */
        -ms-animation: fadeIn 0.5s; /* Internet Explorer */
         -o-animation: fadeIn 0.5s; /* Opera < 12.1 */
            animation: fadeIn 0.5s;
    background-color: rgba(88, 78, 82, 1);
    border: solid 0px rgba(181, 224, 242, 0);
    border-radius: 1em 1em 1em 1em;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    margin: 50px 0 0 0;
    width: 75vw;
  }

  .dropdown-item {
    color: rgba(181, 224, 242, 1);
    letter-spacing: 2px;
    height: 10vh;
    padding: 3vh 0px 0px 10px;
    text-align: center;
  }

  .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:focus:active  {
    background-color: rgba(178, 86, 114, 1);
    color: rgba(181, 224, 242, 1);
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
  .dropdown-item {
    color: rgba(181, 224, 242, 1);
    letter-spacing: 2px;
    height: 4vh;
    padding: 1vh 1vw 1vw 1vh;
    text-align: center;
  }

  .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus, .dropdown-item:focus:active  {
    background-color: rgba(178, 86, 114, 1);
    color: rgba(181, 224, 242, 1);
  }
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
/* Styles */
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
  #navbar {
    display: inline-block;
    background: linear-gradient(to bottom,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
    background: -moz-linear-gradient(to bottom,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
    background: -webkit-linear-gradient(top,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
    /*background-color: rgba(88,78,82,1);*/
    width: 100%;
    position: fixed;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    top: 0px;
    right: 0px;
    z-index: 5;
  }

  #navbar-mobile {
    display: none; /*By default, navbar-mobile is disabled.*/
  }

  #navbar-logo {
    cursor: pointer;
    display: inline;
    float: right;
    height: 100px;
    margin: 30px 30px 10px 20px;
    position: relative;
    border-radius: 10em 10em 10em 10em;
    border: solid 5px rgba(181, 224, 242, 0.1);
  }

    #navbar-logo:hover, #navbar-logo:focus, #navbar-logo:active {
      border: solid 5px rgba(181, 224, 242, 1);
    }

    #navbar-logo:hover:active {
      border: solid 5px rgba(168, 86, 118, 1);
    }

  ul#navbar-list {
    background-color: rgba(181, 224, 242, 0);
    color: rgba(181, 224, 242, 1);
    font-weight: 500;
    font-size: 25px;
    float: right;
    letter-spacing: 3px;
    list-style-type: none;
    margin: 60px 20px 0px 20px;
    padding: 0;
    position: relative;
  }

  ul#navbar-list li {
    background-color: rgba(181, 224, 242, 0);
  }

  li#navbar-list-item-first {
    background-color: none;
    display: inline;
    margin-right: 25px;
  }

  #navbar-first-Link {
    background-color: none;
    color: rgba(181, 224, 242, 1);
    cursor: pointer;
    text-decoration: none;
  }

  #navbar-first-Link:hover, #navbar-first-Link:active {
    color: rgba(255, 255, 255, 1);
  }

  li#navbar-list-item-middle {
    background-color: none;
    display: inline;
    margin-left: 25px;
    margin-right: 25px;
  }

  #navbar-middle-Link {
    background-color: none;
    color: rgba(181, 224, 242, 1);
    cursor: pointer;
    text-decoration: none;
  }

    #navbar-middle-Link:hover, #navbar-first-Link:active {
      color: rgba(255, 255, 255, 1);
    }

  li#navbar-list-item-last {
    background-color: none;
    display: inline;
    margin-left: 25px;
  }

  #navbar-last-Link {
    background-color: none;
    color: rgba(181, 224, 242, 1);
    cursor: pointer;
    text-decoration: none;
  }

    #navbar-last-Link:hover, #navbar-first-Link:active {
      color: rgba(255, 255, 255, 1);
    }
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  #navbar {
    display: inline-block;
    background: linear-gradient(to bottom,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
    background: -moz-linear-gradient(to bottom,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
    background: -webkit-linear-gradient(top,rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,1),rgba(88,78,82,0.8),rgba(88,78,82, 0));
    /*background-color: rgba(88,78,82,1);*/
    width: 100%;
    position: fixed;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    top: 0px;
    right: 0px;
    z-index: 5;
  }

  #navbar-mobile {
    display: none; /*By default, navbar-mobile is disabled.*/
  }

  #navbar-logo {
    cursor: pointer;
    display: inline;
    float: right;
    height: 100px;
    margin: 30px 30px 10px 20px;
    position: relative;
    border-radius: 10em 10em 10em 10em;
    border: solid 5px rgba(181, 224, 242, 0.1);
  }

    #navbar-logo:hover, #navbar-logo:focus, #navbar-logo:active {
      border: solid 5px rgba(181, 224, 242, 1);
    }

    #navbar-logo:hover:active {
      border: solid 5px rgba(168, 86, 118, 1);
    }

  ul#navbar-list {
    background-color: rgba(181, 224, 242, 0);
    color: rgba(181, 224, 242, 1);
    font-weight: 500;
    font-size: 25px;
    float: right;
    letter-spacing: 3px;
    list-style-type: none;
    margin: 60px 20px 0px 20px;
    padding: 0;
    position: relative;
  }

  ul#navbar-list li {
    background-color: rgba(181, 224, 242, 0);
  }

  li#navbar-list-item-first {
    background-color: none;
    display: inline;
    margin-right: 25px;
  }

  #navbar-first-Link {
    background-color: none;
    color: rgba(181, 224, 242, 1);
    cursor: pointer;
    text-decoration: none;
  }

  #navbar-first-Link:hover, #navbar-first-Link:active {
    color: rgba(255, 255, 255, 1);
  }

  li#navbar-list-item-middle {
    background-color: none;
    display: inline;
    margin-left: 25px;
    margin-right: 25px;
  }

  #navbar-middle-Link {
    background-color: none;
    color: rgba(181, 224, 242, 1);
    cursor: pointer;
    text-decoration: none;
  }

    #navbar-middle-Link:hover, #navbar-first-Link:active {
      color: rgba(255, 255, 255, 1);
    }

  li#navbar-list-item-last {
    background-color: none;
    display: inline;
    margin-left: 25px;
  }

  #navbar-last-Link {
    background-color: none;
    color: rgba(181, 224, 242, 1);
    cursor: pointer;
    text-decoration: none;
  }

    #navbar-last-Link:hover, #navbar-first-Link:active {
      color: rgba(255, 255, 255, 1);
    }
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
/* Styles */
}
/* iPhone 6 landscape */
@media only screen and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2)
{ }

/* iPhone 6 portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2)
{ }

/* iPhone 6 Plus landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 3)
{ }

/* iPhone 6 Plus portrait */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 3)
{ }

/* iPhone 6 and 6 Plus */
@media only screen
  and (max-device-width: 640px),
  only screen and (max-device-width: 667px),
  only screen and (max-width: 480px)
{ }
