.landing {
  width: 100vw;
}

h1 {
  color: rgba(255, 255, 255, 1);
  font-size: 60px;
  font-weight: 200;
  letter-spacing: 3px;
  margin: 200px 100px 0px 100px;
  -webkit-animation: fadeIn 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 2s; /* Firefox < 16 */
      -ms-animation: fadeIn 2s; /* Internet Explorer */
       -o-animation: fadeIn 2s; /* Opera < 12.1 */
          animation: fadeIn 2s;
}

h2 {
  color: rgba(181, 224, 242, 1);
  font-size: 40px;
  font-weight: 200;
  letter-spacing: 1px;
  margin: 30px 100px 0px 100px;
  -webkit-animation: fadeIn 3s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 3s; /* Firefox < 16 */
      -ms-animation: fadeIn 3s; /* Internet Explorer */
       -o-animation: fadeIn 3s; /* Opera < 12.1 */
          animation: fadeIn 3s;
}

h3 {
  color: rgba(181, 224, 242, 1);
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 30px 100px 0px 100px;
  -webkit-animation: fadeIn 4s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 4s; /* Firefox < 16 */
      -ms-animation: fadeIn 4s; /* Internet Explorer */
       -o-animation: fadeIn 4s; /* Opera < 12.1 */
          animation: fadeIn 4s;
}

#about-me-header-arrow {
  filter: alpha(opacity=100); /*For IE8 and below*/
  margin: 120px 45vw 0 45vw;
  opacity: 1;
  width: 13%;
  -webkit-animation: fadeIn 6s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadeIn 6s; /* Firefox < 16 */
    -ms-animation: fadeIn 6s; /* Internet Explorer */
     -o-animation: fadeIn 6s; /* Opera < 12.1 */
        animation: fadeIn 6s;
}

label#about-me-header-arrow-label {
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 3px;
  margin: 10px 40vw 0 46vw;
  opacity: 0.2;
  text-align: center;
  -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
   -moz-animation: fadeIn 7s; /* Firefox < 16 */
    -ms-animation: fadeIn 7s; /* Internet Explorer */
     -o-animation: fadeIn 7s; /* Opera < 12.1 */
        animation: fadeIn 7s;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
  h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 30px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 150px 0px 0px 30px;
    -webkit-animation: fadeIn 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 2s; /* Firefox < 16 */
        -ms-animation: fadeIn 2s; /* Internet Explorer */
         -o-animation: fadeIn 2s; /* Opera < 12.1 */
            animation: fadeIn 2s;
  }

  h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 25px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 50px 0px 0px 30px;
    -webkit-animation: fadeIn 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 3s; /* Firefox < 16 */
        -ms-animation: fadeIn 3s; /* Internet Explorer */
         -o-animation: fadeIn 3s; /* Opera < 12.1 */
            animation: fadeIn 3s;
  }

  h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 50px 0px 0px 30px;
    -webkit-animation: fadeIn 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 4s; /* Firefox < 16 */
        -ms-animation: fadeIn 4s; /* Internet Explorer */
         -o-animation: fadeIn 4s; /* Opera < 12.1 */
            animation: fadeIn 4s;
  }

  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 60px 0vw 0 45vw;
    opacity: 1;
    width: 30%;
    -webkit-animation: fadeIn 6s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 6s; /* Firefox < 16 */
      -ms-animation: fadeIn 6s; /* Internet Explorer */
       -o-animation: fadeIn 6s; /* Opera < 12.1 */
          animation: fadeIn 6s;
  }

  label#about-me-header-arrow-label {
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    margin: 10px 40vw 0 46vw;
    opacity: 1;
    text-align: center;
    -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 7s; /* Firefox < 16 */
      -ms-animation: fadeIn 7s; /* Internet Explorer */
       -o-animation: fadeIn 7s; /* Opera < 12.1 */
          animation: fadeIn 7s;
  }
}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 45vw 0 45vw;
    opacity: 1;
    width: 13%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 40vw 0 45vw;
    opacity: 1;
    text-align: center;
  }
}

/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 45vw 0 45vw;
    opacity: 1;
    width: 13%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 40vw 0 42vw;
    opacity: 1;
    text-align: center;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
/* Styles */
}

/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
/* Styles */
}

/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 45vw 0 45vw;
    opacity: 1;
    width: 13%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 40vw 0 44vw;
    opacity: 1;
    text-align: center;
  }
}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
  h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 60px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 200px 100px 0px 100px;
    -webkit-animation: fadeIn 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 2s; /* Firefox < 16 */
        -ms-animation: fadeIn 2s; /* Internet Explorer */
         -o-animation: fadeIn 2s; /* Opera < 12.1 */
            animation: fadeIn 2s;
  }

  h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 30px 100px 0px 100px;
    -webkit-animation: fadeIn 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 3s; /* Firefox < 16 */
        -ms-animation: fadeIn 3s; /* Internet Explorer */
         -o-animation: fadeIn 3s; /* Opera < 12.1 */
            animation: fadeIn 3s;
  }

  h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 100px 0px 100px;
    -webkit-animation: fadeIn 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 4s; /* Firefox < 16 */
        -ms-animation: fadeIn 4s; /* Internet Explorer */
         -o-animation: fadeIn 4s; /* Opera < 12.1 */
            animation: fadeIn 4s;
  }

  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 45vw 0 45vw;
    opacity: 1;
    width: 13%;
    -webkit-animation: fadeIn 6s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 6s; /* Firefox < 16 */
      -ms-animation: fadeIn 6s; /* Internet Explorer */
       -o-animation: fadeIn 6s; /* Opera < 12.1 */
          animation: fadeIn 6s;
  }

  label#about-me-header-arrow-label {
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    margin: 10px 40vw 0 46vw;
    opacity: 1;
    text-align: center;
    -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 7s; /* Firefox < 16 */
      -ms-animation: fadeIn 7s; /* Internet Explorer */
       -o-animation: fadeIn 7s; /* Opera < 12.1 */
          animation: fadeIn 7s;
  }
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
  h1 {
    color: rgba(255, 255, 255, 1);
    font-size: 60px;
    font-weight: 200;
    letter-spacing: 3px;
    margin: 200px 100px 0px 100px;
    -webkit-animation: fadeIn 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 2s; /* Firefox < 16 */
        -ms-animation: fadeIn 2s; /* Internet Explorer */
         -o-animation: fadeIn 2s; /* Opera < 12.1 */
            animation: fadeIn 2s;
  }

  h2 {
    color: rgba(181, 224, 242, 1);
    font-size: 40px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 30px 100px 0px 100px;
    -webkit-animation: fadeIn 3s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 3s; /* Firefox < 16 */
        -ms-animation: fadeIn 3s; /* Internet Explorer */
         -o-animation: fadeIn 3s; /* Opera < 12.1 */
            animation: fadeIn 3s;
  }

  h3 {
    color: rgba(181, 224, 242, 1);
    font-size: 30px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 30px 100px 0px 100px;
    -webkit-animation: fadeIn 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeIn 4s; /* Firefox < 16 */
        -ms-animation: fadeIn 4s; /* Internet Explorer */
         -o-animation: fadeIn 4s; /* Opera < 12.1 */
            animation: fadeIn 4s;
  }

  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 45vw 0 45vw;
    opacity: 1;
    width: 13%;
  }

  label#about-me-header-arrow-label {
    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 3px;
    margin: 10px 40vw 0 46vw;
    opacity: 1;
    text-align: center;
    -webkit-animation: fadeIn 7s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeIn 7s; /* Firefox < 16 */
      -ms-animation: fadeIn 7s; /* Internet Explorer */
       -o-animation: fadeIn 7s; /* Opera < 12.1 */
          animation: fadeIn 7s;
  }
}

/* iPhone 4 ----------- */
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {
}
/* iPhone 6 landscape */
@media only screen and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 2)
{
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 0vw 0 45vw;
    opacity: 1;
    width: 13%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 0vw 0 44vw;
    opacity: 1;
    text-align: center;
  }
}

/* iPhone 6 portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 2)
{
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 0vw 0 45vw;
    opacity: 1;
    width: 13%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 0vw 0 44vw;
    opacity: 1;
    text-align: center;
  }
}

/* iPhone 6 Plus landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 3)
{
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 0vw 0 45vw;
    opacity: 1;
    width: 13%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 0vw 0 44vw;
    opacity: 1;
    text-align: center;
  }
}

/* iPhone 6 Plus portrait */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (orientation: portrait)
  and (-webkit-min-device-pixel-ratio: 3)
{
  #about-me-header-arrow {
    filter: alpha(opacity=50); /*For IE8 and below*/
    margin: 120px 0vw 0 40vw;
    opacity: 1;
    width: 25%;
  }
  label#about-me-header-arrow-label {
    margin: 10px 40vw 0 44vw;
    opacity: 1;
    text-align: center;
  }
}

/* iPhone 6 and 6 Plus */
@media only screen
  and (max-device-width: 640px),
  only screen and (max-device-width: 667px),
  only screen and (max-width: 480px)
{ }
